



















































import { Component, Prop, Watch } from "vue-property-decorator";
import BaseInput from "~/core/BaseInput";

@Component
export default class AutoComplete extends BaseInput {
  @Prop() isLoading?: boolean;
  @Prop({ default: "" }) type?: "icon-header-sub" | "";
  @Prop({ default: "" }) displayValue?: string;
  @Prop({ default: "" }) searchProp!: string;
  @Prop({ default: true }) clearable!: boolean;
  @Prop() filterF?: Function;

  search = null;

  @Watch("search")
  onSearch(newValue) {
    this.$emit("onSearch", newValue);
  }

  @Watch("searchProp")
  onSearchPropChange(newValue) {
    this.search = newValue;
  }

  onEmitKeycode(key: any) {
    this.$emit("keycode", key.keyCode);
  }
}
