
























import {Component, Prop, Watch} from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class InputFile extends BaseInput {
  @Prop({default: 'image/*'}) accept!: string

  file: any = null

  @Watch('file')
  async onFileChange() {
    this.$emit('filechanged', this.file)
  }
}
