import { Base } from './Base'
import { WalletLoader } from '~/loader/WalletLoader'
import { 
  getChainId } from '~/utils/util'
import { 
    getAddressOfContract,
} from '~/consts/config'

export class BaseApp extends Base {

  async addChain(chainId: string) {
    const provider = this.$web3.currentProvider as any
    if (chainId == '0x61') {
      await provider.sendAsync({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x61',
          chainName: 'BSC Testnet',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
          },
          rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
          blockExplorerUrls: ['https://testnet.bscscan.com']
        }],
        id: Math.round(Math.random() * 100000),
      })
    }
  }

  async switchChain(chainId: string) {
    const provider = this.$web3.currentProvider as any
    await provider.sendAsync({
      method: 'wallet_switchEthereumChain',
      params: [{chainId: chainId}],
      id: Math.round(Math.random() * 100000),
    })
  }

  get walletAddress() {
    const addrs = this.walletLoader().callItems
    if (addrs && addrs.length > 0) {
      return addrs[0]
    }
    return null
  }

  get isLogin(): boolean {
    const wallet = this.walletLoader()
    return wallet.callItems != null && wallet.callItems.length > 0
  }

  async addToken(token: string) {
    const addr = getAddressOfContract(token)
    const provider = this.$web3.currentProvider as any
    await provider.sendAsync({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', 
        options: {
          address: addr, 
          symbol: token, 
          decimals: 18,
        }
      },
      id: Math.round(Math.random() * 100000),
    })
  }

  walletLoader(
    success: (IStatus) => void = null, 
    err: (IStatus) => void = null): WalletLoader<string> {
    
    return new WalletLoader<string>(this, (_: any) => ({
      handle: null,
      stateKey: 'my_wallet',
      onCallSuccess: success,
      onCallError: err,
    }))
  }

}