import { IOption } from "~/components/form/FormTypes"
import { _get } from "./lodash"

export class ArrayHelper {
  static toArray<T>(value: any): (T | any)[] {
    return Array.from(value || [])
  }

  static isEmpty(value: any): boolean {
    return this.toArray(value).length === 0
  }

  static isExist(data: any[], value: any): boolean {
    let isHas = false

    ArrayHelper.toArray(data).forEach((item) => {
      if (item === value) {
        isHas = true
      }
    })

    return isHas
  }

  static toOptions<T = any> (
    data: T[],
    valueAttr: string = 'id',
    labelAttr: string = 'name',
    prefixLabel = ''
  ): IOption[] {
    return ArrayHelper.toArray(data).map((item: any) => {
      const value = _get(item, valueAttr, null)
      return {
        value: value,
        label: `${prefixLabel && `${prefixLabel}: `}${_get(item, labelAttr, value)}`
      }
    })
  }
}
