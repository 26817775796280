



















import { Component, Prop } from 'vue-property-decorator'
import { Base } from '~/core/Base'

@Component
export default class ButtonPrimary extends Base {
  @Prop(String) readonly icon!: string
  @Prop(Boolean) readonly fullWidth!: boolean
  @Prop(Boolean) readonly iconOnly!: boolean
  @Prop(Boolean) readonly disabled!: boolean
  @Prop(Boolean) readonly isLoading!: boolean
  @Prop({type: String, default: ''}) readonly inputWidth!: string
  @Prop({type: Boolean, default: false}) readonly isOutlined!: boolean
  @Prop({type: String, default: 'submit'}) readonly type!: string
}
