
export const DefaultAvatarURL = process.env.DEFAULT_AVATAR_URL
export const DefaultAvatarName = process.env.DEFAULT_AVATAR_NAME
export const DefaultAvatarOccupation = process.env.DEFAULT_AVATAR_OCCUPATION

export const BlockIn1Day = 28800

export const ContractNames = {
  // CityToken: 'City',
  MetaLand: 'MetaLand',
  MetaCity: 'MetaCity',
  MetaItem: 'MetaItem',
  MetaMarket: 'MetaMarket',
  MetaTweet: 'MetaTweet',
  SecondaryMarket: 'SecondaryMarket',
  BUSD: 'BUSD',
  // USDT: 'USDT',
  // USDC: 'USDC',
  BuyCity: 'BuyCity',
}

export const getPathWithBaseURL = (path: string): string => {
  const baseURL = process.env.BASE_URL
  return `${baseURL}${path}`
}

export const getAPIEndpoint = (path: string): string => {
  const baseURL = process.env.API_ENDPOINT
  return `${baseURL}${path}`
}

export const getAddressOfContract = (contractName: string): string => {
  switch (contractName) {
    // case ContractNames.CityToken: return process.env.CITY_ADDR
    case ContractNames.MetaLand: return process.env.METALAND_ADDR
    case ContractNames.MetaCity: return process.env.METACITY_ADDR
    case ContractNames.MetaItem: return process.env.METAITEM_ADDR
    case ContractNames.MetaMarket: return process.env.METAMARKET_ADDR
    case ContractNames.MetaTweet: return process.env.METATWEET_ADDR
    case ContractNames.BUSD: return process.env.BUSD_ADDR
    case ContractNames.SecondaryMarket: return process.env.SECONDARYMARKET_ADDR
    // case ContractNames.USDT: return process.env.USDT_ADDR
    // case ContractNames.USDC: return process.env.USDC_ADDR
    // case ContractNames.BuyCity: return process.env.BUYCITY_ADDR
  }
  return 'unknown'
}