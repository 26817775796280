export const BaseAutoComplete = () => import('../../components/base/AutoComplete.vue' /* webpackChunkName: "components/base-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonPrimary = () => import('../../components/base/ButtonPrimary.vue' /* webpackChunkName: "components/base-button-primary" */).then(c => wrapFunctional(c.default || c))
export const BaseImageFile = () => import('../../components/base/ImageFile.vue' /* webpackChunkName: "components/base-image-file" */).then(c => wrapFunctional(c.default || c))
export const BaseInputFile = () => import('../../components/base/InputFile.vue' /* webpackChunkName: "components/base-input-file" */).then(c => wrapFunctional(c.default || c))
export const BaseInputText = () => import('../../components/base/InputText.vue' /* webpackChunkName: "components/base-input-text" */).then(c => wrapFunctional(c.default || c))
export const BaseLoading = () => import('../../components/base/Loading.vue' /* webpackChunkName: "components/base-loading" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/Textarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const FormField = () => import('../../components/form/Field.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCondition = () => import('../../components/form/FieldCondition.vue' /* webpackChunkName: "components/form-field-condition" */).then(c => wrapFunctional(c.default || c))
export const FormTypes = () => import('../../components/form/FormTypes.ts' /* webpackChunkName: "components/form-types" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
