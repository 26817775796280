






















import { Component, Prop } from 'vue-property-decorator'
import BaseInput from '~/core/BaseInput'

@Component
export default class Textarea extends BaseInput {
  @Prop({default: '4'}) readonly rowsAmount!: string
}
