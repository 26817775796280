






import { Component } from 'vue-property-decorator'
import { BaseApp } from '~/core/BaseApp'

@Component
export default class App extends BaseApp {}
