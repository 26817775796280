



































import { Component, Prop } from 'vue-property-decorator'
import { Base } from '~/core/Base'
import { IFormOption, INPUT_TYPES } from '~/components/form/FormTypes'

@Component
export default class FieldCondition extends Base {
  @Prop(Object) readonly option!: IFormOption
  @Prop(Object) readonly value!: IFormOption

  isTextField(): boolean {
    return this.option.type === INPUT_TYPES.TEXT
  }

  isSelect(): boolean {
    return this.option.type === INPUT_TYPES.SELECT
  }

  isTextarea(): boolean {
    return this.option.type === INPUT_TYPES.TEXTAREA
  }

  isAutoComplete(): boolean {
    return this.option.type === INPUT_TYPES.AUTOCOMPLETE;
  }

  isFileField(): boolean {
    return this.option.type === INPUT_TYPES.FILE;
  }
}
