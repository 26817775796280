







import { Component, Prop } from 'vue-property-decorator'
import { Base } from '~/core/Base';

@Component
export default class LoadingPage extends Base {
  @Prop(Boolean) readonly loading!: boolean
  @Prop(String) readonly loadingText!: string
}
