import Vue from 'vue'
import Field from '~/components/form/Field.vue'
import InputText from '~/components/base/InputText.vue'
import Select from '~/components/base/Select.vue'
import ButtonPrimary from '~/components/base/ButtonPrimary.vue'
import Textarea from '~/components/base/Textarea.vue'
import Loading from '~/components/base/Loading.vue'
import AutoComplete from '~/components/base/AutoComplete.vue'
import InputFile from '~/components/base/InputFile.vue'

Vue.component('Field', Field)
Vue.component('InputText', InputText)
Vue.component('Select', Select)
Vue.component('ButtonPrimary', ButtonPrimary)
Vue.component('Textarea', Textarea)
Vue.component('Loading', Loading)
Vue.component('AutoComplete', AutoComplete)
Vue.component('InputFile', InputFile)